import { watch } from 'vue';
import store from '../store';
import isEqual from 'lodash/isEqual';

let acceptUpdates = false;

watch(
  () => store.state.workflows.details,
  (newState) => {
    const workflowId = newState?.workflow?.id;
    if (workflowId && acceptUpdates) {
      const workflowState = extractWorkflowState(newState);
      saveState(`workflow:${workflowId}`, workflowState);
    }
  },
  { immediate: true, deep: true }
);

function extractWorkflowState(state) {
  return {
    workflow: state.workflow,
    credentials: state.credentials,
    startSettings: state.startSettings,
    nodes: state.nodes,
    published: state.workflow.published,
    alertSettings: state.workflow.alertSettings,
    s3StorageEnabled: state.workflow.IOStorageType === 's3',
  };
}

export function overwriteWorkflowState(key) {
  try {
    const workflowState = loadState(key);
    if (!workflowState?.workflow) {
      return;
    }
    const prefix = 'workflows/details';
    const published = workflowState.workflow.published;
    const alertSettings = workflowState.workflow.alertSettings;
    const s3StorageEnabled = workflowState.workflow.IOStorageType === 's3';

    store.commit(`${prefix}/SET_AUTOMATION_WORKFLOW`, workflowState.workflow);
    store.commit(`${prefix}/SET_WORKFLOW_SELECT_VERSION`, published);
    store.commit(`${prefix}/SET_ALERT_SETTINGS`, alertSettings);
    store.commit(`${prefix}/SET_S3_STORAGE_ENABLED`, s3StorageEnabled);
    store.commit(`${prefix}/SET_CREDENTIALS`, workflowState.credentials);
    store.commit(`${prefix}/SET_START_SETTINGS`, workflowState.startSettings);
    store.commit(`${prefix}/SET_NODES`, workflowState.nodes);
  } catch {
    // ignore
  } finally {
    acceptUpdates = true;
  }
}

function loadState(key) {
  try {
    return JSON.parse(localStorage.getItem(key));
  } catch (error) {
    return null;
  }
}

export function compareLocalState(key) {
  try {
    const savedState = loadState(key);
    if (!savedState?.workflow) {
      return false;
    }
    const currentState = extractWorkflowState(store.state.workflows.details);
    return !isEqual(currentState, savedState);
  } catch (error) {
    console.error('Error comparing states:', error);
    return false;
  }
}

export function clearLocalState(key) {
  localStorage.removeItem(key);
  saveState(key, store.state.workflows.details);
}

export function enableStateUpdates() {
  acceptUpdates = true;
}

function saveState(key, value) {
  localStorage.setItem(key, JSON.stringify(value));
}

const mutations = {
  SET_INVOICES: (state, payload) => {
    state.invoices = payload;
  },
  SET_FETCH_INVOICES_LOADING: (state, payload) => {
    state.fetchInvoicesLoading = payload;
  },
  SET_TOTAL_INVOICES_COUNT: (state, payload) => {
    state.totalInvoicesCount = payload;
  },
  SET_UNPAID_INVOICES: (state, payload) => {
    state.unpaidInvoices = payload;
  },
  SET_FETCH_INVOICE_DETAILS_LOADING: (state, payload) => {
    state.fetchInvoiceDetailsLoading = payload;
  },
  SET_INVOICE_DETAILS: (state, payload) => {
    state.invoiceDetails = payload;
  },
  SET_CHARGE_UPDATE_LOADING: (state, payload) => {
    state.chargeUpdateLoading = payload;
  },
  SET_SEND_REMINDER_LOADING: (state, payload) => {
    state.sendReminderLoading = payload;
  },
  SET_FETCH_CARDS_LOADING: (state, payload) => {
    state.fetchCardsLoading = payload;
  },
  SET_CARDS: (state, payload) => {
    state.cards = payload;
  },
  SET_DELETE_CARD_LOADING: (state, payload) => {
    state.deleteCardLoading = payload;
  },
  SET_PRIMARY_CARD_LOADING: (state, payload) => {
    state.primaryCardLoading = payload;
  },
  SET_SAVE_NEW_CARD_LOADING: (state, payload) => {
    state.saveNewCardLoading = payload;
  },
  SET_PAYMENT_DETAILS: (state, payload) => {
    state.paymentDetails = payload;
  },
  SET_AUTOPAYMENT: (state, payload) => {
    state.autoPayment = payload;
  },
  SET_PATIENT_BALANCE: (state, payload) => {
    state.balance = payload;
  },
  SET_SAVE_PAYMENT_PLAN_LOADING: (state, payload) => {
    state.savePaymentPlanLoading = payload;
  },
  SET_FETCH_PAYMENT_PLAN_LOADING: (state, payload) => {
    state.fetchPaymentPlanLoading = payload;
  },
  SET_PAYMENT_PLANS: (state, payload) => {
    state.paymentPlans = payload;
  },
  SET_PAYMENT_PLANS_TOTAL: (state, payload) => {
    state.paymentPlansTotal = payload;
  },
  SET_TOGGLE_BATCH_STATUS_LOADING: (state, payload) => {
    state.toggleBatchStatusLoading = payload;
  },
  SET_CREATE_PAYMENT_PLAN_LOADING: (state, payload) => {
    state.createPaymentPlanLoading = payload;
  },
  SET_STANDALONE_INVOICES: (state, payload) => {
    state.standaloneInvoices = payload;
  },
  SET_FETCH_STANDALONE_INVOICES_LOADING: (state, payload) => {
    state.standaloneInvoicesLoading = payload;
  },
  SET_STANDALONE_INVOICES_TOTAL: (state, payload) => {
    state.standaloneInvoicesTotal = payload;
  },
  SET_SEND_BALANCE_TRANSACTION_LOADING: (state, payload) => {
    state.sendBalanceTransactionLoading = payload;
  },
  SET_CREATE_INVOICE_LOADING: (state, payload) => {
    state.createInvoiceLoading = payload;
  },
};

export default mutations;

import {workflowStatus} from "@/util/workflow-statuses";
import cloneDeep from "lodash/cloneDeep";

const resumeWorkflow = async ({ rootGetters, dispatch, commit }, { storageId, workflowId }) => {
  try {
    commit('SET_RESUME_WORKFLOW_LOADING', true);
    let running;
    if(workflowId) {
      running = cloneDeep(rootGetters['workflows/details/WORKFLOW_RUNNING_OBJ'](workflowId));
    }

    const customer = rootGetters['customer/CUSTOMER'];
    const selected_customer = rootGetters['customer/SELECTED_CUSTOMER'];
    const data = { customer_id: customer.customer_id, storageId };
    if (customer.customer_id === 0) {
      data.customer_id = selected_customer.customer_id;
    }

    const response = await window.axios.post('/proxy/resume-workflow', data);

    if (!response.data.success && running) {
      running = {
        ...running,
        workflow_status: workflowStatus.FAILURE,
        workflowId
      };
      commit('workflows/details/SET_WORKFLOW_RUNNING_OBJ', running, { root: true });
    } else if(running) {
      const { storage_id } = response.data;
      setTimeout(() => {
        const workflow = rootGetters['workflows/details/WORKFLOW'];
        dispatch('workflows/details/getWorkflowStatus', { storage_id, firstCall: true, workflowId, isServerless: workflow.isServerless || false }, { root: true });
      }, 2000);
    }

    if (response && response.data.success) {
      dispatch('notifications/pushNotification', 'Workflow Resumed', {
        root: true,
      });
    } else {
      throw response.data;
    }
  } catch (error) {
    dispatch('notifications/setError', error.message, { root: true });
  } finally {
    commit('SET_RESUME_WORKFLOW_LOADING', false);
  }
};
export default resumeWorkflow;

import Cookies from "js-cookie";

const restoreCommit = async ({ commit, rootGetters, dispatch }, { workflow_id, version_id, commit_id }) => {
    commit('SET_RESTORE_COMMIT_LOADING', true);
    try {
        const customer = rootGetters['customer/CUSTOMER'];
        const selected_customer = rootGetters['customer/SELECTED_CUSTOMER'];

        let url = `/proxy/get-workflows/${workflow_id}/versions/${version_id}/restore-commit/${commit_id}`;
        let customer_id = customer.customer_id;
        if (customer_id === 0) {
            customer_id = selected_customer.customer_id;
        }

        const data = { customer_id, user_id: Cookies.get('userId') };
        const response = await window.axios.post(url, data);
        if (response && response.data.success) {
            if(response.data.version) {
                commit('UPDATE_WORKFLOW_VERSION_LIST', response.data.version)
            }
            dispatch('notifications/pushNotification', 'Commit restored', {
                root: true,
            });
            return { success: true };
        } else {
            dispatch('notifications/setError', response.data.message, { root: true });
            return { success: true };
        }
    } catch (error) {
        dispatch('notifications/setError', error.message, { root: true });
        return { success: false, message: error.message };
    } finally {
        commit('SET_RESTORE_COMMIT_LOADING', false);
    }
};

export default restoreCommit;
